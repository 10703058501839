<template>
  <div v-if="loading" class="login-loader">
    <loader/>
  </div>

  <div v-else class="rounded-table">
    <!-- if brainstorm online, is not started and no available tables -->
    <div
      v-if="!allTablesAvailable && !brainstormIsOn && !themeShow"
      class="brainstorm-message"
    >
      {{ $t("Brainstorm.brainstormIsntStarted") }}
    </div>

    <!-- if brainstorm offline and is not started -->
    <div v-if="themeShow">
      <table-theme-idea
        v-if="allIdea === 'tables'"
        @statusIdea="statusIdea"
      />

      <rounded-tables
        v-if="allIdea === 'active'"
        :brainstorm-title="currentBrainstormTitle"
        @statusIdea="statusIdea"
      />
    </div>
    <template v-else>
      <!-- if brainstorm is started -->
      <div
        v-if="currentBrainstormTitle && brainstormIsOn"
        class="brainstorm-title"
      >
        {{ currentBrainstormTitle }}
      </div>

      <!-- if brainstorm is started and speaker shows no ideas -->
      <div
        v-if="brainstormIsOn && !allTablesAvailable"
        class="brainstorm-message"
      >
        {{ $t("Brainstorm.suggestFirst") }}
      </div>
      <speaker-table-questions
        v-if="allTablesAvailable && !brainstormIsOn"
      />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import SpeakerTableQuestions from "./components/SpeakerTableQuestions";
import Loader from "@/components/common/elements/Loader";
import { BRAINSTORM_TITLE } from "@/store/modules/brainstorm/getter-types";
import { brainstorm, room } from "@/store/modules/store.namespaces";
import {
  GET_BRAINSTORMS,
  GET_ROUNDED_TABLE_IDEAS,
} from "@/store/modules/brainstorm/action-types";
import TableThemeIdea from "@/components/event/room/Brainstorm/components/TableThemeIdea";
import RoundedTables from "@/components/event/room/Brainstorm/components/RoundedTable";

const LIKES_UPDATE_INTERVAL = 5 * 1000;

let likesTimeout = null;

export default {
  components: {
    SpeakerTableQuestions,
    Loader,
    TableThemeIdea,
    RoundedTables,
  },
  data() {
    return {
      currentTab: "all",
      loading: false,
      allIdea: "tables",
    };
  },
  computed: {
    ...mapGetters(brainstorm, {
      currentBrainstormTitle: BRAINSTORM_TITLE,
    }),
    ...mapState(room, {
      allTablesAvailable: state => state.roomInfo.Brainstorm?.status == "2",
      brainstormIsOn: state => state.roomInfo.Brainstorm?.status == "1",
      themeShow: state => state.roomInfo.theme_show,
    }),
  },
  watch: {
    allTablesAvailable(newVal) {
      if (!newVal) {
        this.currentTab = "group";
      }
    },
  },
  created() {
    this.getBrainstorms();
    this.getRoundedTablesIdeas(true);
  },
  mounted() {
    this.renewLikesByInterval();
  },
  beforeDestroy() {
    if (likesTimeout) {
      clearTimeout(likesTimeout);
    }
  },
  methods: {
    ...mapActions(brainstorm, {
      getRoundedTablesIdeas: GET_ROUNDED_TABLE_IDEAS,
      getBrainstorms: GET_BRAINSTORMS,
    }),
    statusIdea(status) {
      this.allIdea = status;
    },
    renewLikesByInterval() {
      likesTimeout = setTimeout(() => {
        this.getRoundedTablesIdeas(false);
        this.renewLikesByInterval();
      }, LIKES_UPDATE_INTERVAL);
    },
  },
};
</script>

<style scoped lang="less">
.rounded-table {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 20px;

  .brainstorm-title {
    margin: 15px 0;
    font-size: 32px;
    text-align: center;
  }

  .brainstorm-message {
    margin: 15px 0;
    margin-top: 25%;
    font-size: 36px;
    text-align: center;
    color: black;
  }

  .switch-tabs {
    display: inline-block;
    flex-grow: 0;
    margin: 15px auto 0;
    font-size: 13px;
    border-radius: 16px;

    .pill {
      display: inline-block;
      padding: 5px 15px;
      border: 1px solid #4e4748;
      white-space: nowrap;
      transition: all 0.3s;
      cursor: pointer;

      &:first-of-type {
        border-bottom-left-radius: 16px;
        border-top-left-radius: 16px;
      }

      &:last-of-type {
        border-bottom-right-radius: 16px;
        border-top-right-radius: 16px;
      }

      &.active {
        background-color: #4e4748;
        color: #fff;
      }
    }
  }

  .messenger-wrapper {
    flex-shrink: 0;
    padding: 14px 0;
    margin-top: auto;
    border-top: 1px solid #ebebeb;
  }
}
</style>
